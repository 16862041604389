import React, { Component } from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import Button from "../components/button";
import ButtonBlog from "../components/buttonblog";
import RoundButton from "../components/roundButton";
// import PageTransition from "gatsby-plugin-page-transitions";
import SEO from "../components/seo";
import LastPosts from '../components/lastposts';
import RappelForm from "../components/rappelForm";
import Accordion from '../components/accordion';
import RoundButtonPages from "../components/roundButtonPages";
import RoundButtonPagesMail from "../components/roundButtonPagesMail";
import SimpleMap from "../components/simpleMap";
import Partenaires from '../components/partenaires';

import blocBg from "../images/background.jpg";
// import Bg from "../images/background-referencement.jpg";
import Bg from "../images/referencement-toulouse-bg.jpg";
import imac from "../images/agence-web-agen.jpg";
// import bgDev from '../images/bg-dev.jpg';
import mac from "../images/mac-linkweb.jpg";



import scroll from "../images/motiontest.svg";
import avsmoto from '../images/avsmoto-avis.png';
import casinobarbotan from '../images/casino-barbotan-avis.png';
import contis from '../images/contis-avis.png';
import "../scss/info.scss";
import '../scss/global.scss';

class Nomsdedomaine extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Layout location={this.props.location}>

                <SEO
                    title="Noms de domaines .fr - Enregistrement de noms de domaines -  Linkweb"
                    description=""
                    keywords={[`Noms de domaines`, `Nom de domaine`]}
                    url="https://linkweb.fr/noms-de-domaines/"
                    nom="Noms de domaines"
                    slug="noms-de-domaines/"
                />
                {/* <PageTransition
                    defaultStyle={{
                        transition: 'left 200ms cubic-bezier(0.47, 0, 0.75, 0.72)',
                        transitionDuration: '0.5s',
                        transitionDelay: '0.1s',
                        //left: '100%',
                        position: 'relative',
                        width: '100%',
                        zIndex: 20,
                        minHeight: '100vh',
                    }}
                    transitionStyles={{
                        entering: { left: '-100%' },
                        entered: { left: '0' },
                        exiting: { left: '-100%' },

                    }}
                    transitionTime={200}
                > */}
                <section className="flex items-center w-full mx-auto pb-0 mt-40 mb-24 px-4 py-6 bg-black flex flex-col justify-center items-center" style={{ background: '#000000ad url(' + Bg + ')', backgroundBlendMode: 'overlay', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center top' }}>
                    <div className="max-w-6xl mx-auto lg:mx-56 pt-2 lg:w-2/5 w-full flex-col items-end">
                        <div className="mt-12 -mb-24 w-full flex flex-col items-center lg:items-start">
                            <div className="w-full  text-3xl text-center font-bold text-white century">4.6/5</div>
                            <p className="w-full  text-sm text-center font-bold text-white century -mt-2">Avis Google</p>
                            <div className="w-full -mt-8">
                                <svg className="mx-auto block" width="100px" viewBox="0 0 100 125">
                                    <path fill="#FFFFFF" d="M19.3,47.7l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.8-2.5l4.9,2.5c0.1,0,0.1,0.1,0.2,0.1  c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C19.6,47.8,19.5,47.7,19.3,47.7z" />
                                    <path fill="#FFFFFF" d="M38.8,47.7l-5.4-0.8L31,42c-0.1-0.2-0.4-0.3-0.7-0.2c-0.1,0-0.2,0.1-0.2,0.2l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8L25,57.8c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5c0.2,0.1,0.4,0.1,0.5,0  c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C39.2,47.8,39,47.7,38.8,47.7z" />
                                    <path fill="#FFFFFF" d="M58.3,47.7l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5c0.1,0,0.1,0.1,0.2,0.1  c0.1,0,0.2,0,0.3-0.1c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C58.6,47.8,58.5,47.7,58.3,47.7z" />
                                    <path fill="#FFFFFF" d="M78.2,48c-0.1-0.2-0.2-0.3-0.4-0.3l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.4-0.3-0.7-0.2c-0.1,0-0.2,0.1-0.2,0.2l-2.4,4.9l-5.4,0.8  c-0.2,0-0.3,0.2-0.4,0.3c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5  c0.2,0.1,0.4,0.1,0.5,0c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8C78.2,48.4,78.2,48.2,78.2,48z" />
                                    <path fill="#FFFFFF" d="M95.1,58.3c-0.1,0-0.2,0-0.2-0.1l-4.8-2.6l-4.9,2.6c-0.2,0.1-0.5,0-0.7-0.2c-0.1-0.1-0.1-0.2,0-0.3l0.9-5.4l-4-3.8  c-0.2-0.2-0.2-0.5,0-0.7c0.1-0.1,0.2-0.1,0.3-0.1l5.4-0.8l2.4-4.9c0.1-0.2,0.4-0.3,0.7-0.2c0.1,0,0.2,0.1,0.2,0.2l2.4,4.9l5.4,0.8  c0.3,0,0.4,0.3,0.4,0.6c0,0.1-0.1,0.2-0.1,0.3l-3.9,3.8l0.9,5.4C95.6,58,95.5,58.3,95.1,58.3C95.2,58.3,95.1,58.3,95.1,58.3z   M89.8,54.7c0.1-0.1,0.4,0,0.4,0l4.2,2.2l-0.8-4.7c0-0.2,0-0.3,0.1-0.4l3.4-3.3l-4.7-0.7c-0.2,0-0.3-0.1-0.4-0.3l-2.1-4.3L89.8,54.7  z" />
                                </svg>
                            </div>
                        </div>
                        <div className="w-full lg:w-full flex justify-center lg:justify-center">
                            <h1 className="text-4xl md:text-6xl my-10 font-bold text-center text-white century leading-none">Enregistrement de noms de domaines .fr</h1>
                        </div>
                        <br />
                        {/* <h2 className="flex full lg:w-full text-white justify-center lg:justify-center mx-auto lg:mx-0 text-xl  century text-center p-1 -mt-12">Bureau d'enregistrement de nom de domaine agréé Afnic.</h2> */}
                        {/*<SmallTitle className="flex flex-1 smalltitle text-white justify-center lg:justify-start century px-2" pose={this.state.isOpen ? 'open' : 'closed'}>Connectez-vous avec le reste du monde</SmallTitle>*/}
                        <div className="w-full lg:w-full flex flex-col lg:flex-row justify-center pb-24 lg:justify-center mx-auto" style={{ maxWidth: '460px' }}>
                            <RoundButtonPages url="tel:0533950030" text="05 33 95 00 30" />
                            <RoundButtonPagesMail url="/contact-agence-web-toulouse/" text="Demander un devis" />
                        </div>
                    </div>
                    <div className="w-full flex items-end justify-center mx-auto block pb-16">
                        <img src={scroll} alt="Scroll" width="60px" />
                    </div>
                </section>
                <section className="bg-white phototext py-10">
                <section className="text-center text-black text-2xl mx-auto flex-col flex items-center lg:mx-0 century mt-16 mb-10">
                    <h2 className="century text-center lg:text-left text-3xl md:text-5xl leading-none">Créez ou transférez votre nom de domaine .fr</h2>
                        <br />
                        <hr className="border-solid w-1/6 border-bleu"></hr>
                        <br />
                    </section>
                    <section className="flex text-black justify-center flex-col items-center">
                        <div className="w-full px-12 lg:px-0 flex justify-center flex-col md:flex-row lg:w-3/4 mt-5">
                            <div className="w-full svgtransform md:w-1/4 mx-auto px-2 mb-10 md:mb-0 century text-center lg:text-left">
                            <svg width="70px" fill="#37cfee" className="lg:mx-0 -mt-2 mx-auto block" viewBox="0 0 700 700">
                                <g>
                                <path d="m523.07 131.95h-346.15c-19.25 0-34.824 15.574-34.824 34.824v226.27c0 19.25 15.574 34.824 34.824 34.824 4.8984 0 8.75-3.8516 8.75-8.75 0-4.8984-3.8516-8.75-8.75-8.75-9.625 0-17.324-7.6992-17.324-17.324v-170.8h380.98v170.98c0 9.625-7.6992 17.324-17.324 17.324-4.8984 0-8.75 3.8516-8.75 8.75 0 4.8984 3.8516 8.75 8.75 8.75 19.25 0 34.824-15.574 34.824-34.824l-0.003906-226.28c-0.17578-19.25-15.75-35-35-35zm-363.47 72.801v-37.801c0-9.625 7.6992-17.324 17.324-17.324h346.32c9.625 0 17.324 7.6992 17.324 17.324v37.801z"/>
                                <path d="m195.3 167.82c-6.125 0-11.199 4.8984-11.199 11.199 0 6.125 4.8984 11.199 11.199 11.199s11.199-4.8984 11.199-11.199c-0.17578-6.2969-5.0742-11.199-11.199-11.199z"/>
                                <path d="m231.35 167.82c-6.125 0-11.199 4.8984-11.199 11.199 0 6.125 4.8984 11.199 11.199 11.199 6.3008 0 11.199-4.8984 11.199-11.199-0.17578-6.2969-5.0742-11.199-11.199-11.199z"/>
                                <path d="m267.4 167.82c-6.125 0-11.199 4.8984-11.199 11.199 0 6.125 4.8984 11.199 11.199 11.199 6.3008 0 11.199-4.8984 11.199-11.199 0.003906-6.2969-5.0742-11.199-11.199-11.199z"/>
                                <path d="m289.45 396.55c-1.2266 5.7734-2.2734 11.551-2.9766 17.148h-0.17578c-0.875-5.7734-2.2734-11.199-3.6758-16.977l-5.0742-19.949h-12.25l-5.4258 20.648c-1.0508 4.8984-2.625 10.5-3.5 16.273h-0.35156c-0.875-5.7734-1.9258-11.199-2.8008-16.449l-4.0234-20.648-15.922 0.003906 14.699 50.227h14.699l5.0742-17.676c1.2266-4.5508 2.2734-9.2734 3.1484-15.574h0.17578c1.0508 6.3008 1.9258 10.852 3.3242 15.75l4.7266 17.5h14.523l15.398-50.227h-15.227z"/>
                                <path d="m369.25 396.55c-1.2266 5.7734-2.2734 11.551-2.9766 17.148h-0.17578c-0.875-5.7734-2.2734-11.199-3.6758-16.977l-5.0742-19.949h-12.25l-5.4258 20.648c-1.0508 4.8984-2.625 10.5-3.5 16.273h-0.35156c-0.875-5.7734-1.9258-11.199-2.8008-16.449l-4.0234-20.648h-15.926l14.699 50.227h14.699l5.0742-17.676c1.2266-4.5508 2.2734-9.2734 3.1484-15.574h0.17578c1.0508 6.3008 1.9258 10.852 3.3242 15.75l4.7266 17.5h14.523l15.398-50.227h-15.227z"/>
                                <path d="m449.05 396.55c-1.2266 5.7734-2.2734 11.551-2.9766 17.148h-0.17578c-0.875-5.7734-2.2734-11.199-3.6758-16.977l-5.0742-19.949h-12.25l-5.4258 20.648c-1.0508 4.8984-2.625 10.5-3.5 16.273h-0.35156c-0.875-5.7734-1.9258-11.199-2.8008-16.449l-4.0234-20.648h-15.926l14.699 50.227h14.699l5.0742-17.676c1.2266-4.5508 2.2734-9.2734 3.1484-15.574h0.17578c1.0508 6.3008 1.9258 10.852 3.3242 15.75l4.7266 17.5h14.523l15.398-50.227-15.391 0.003906z"/>
                                <path d="m479.15 408.98c-5.4258 0-9.2734 3.8516-9.2734 9.4492 0 5.4258 3.8516 9.4492 9.1016 9.4492 5.6016 0 9.2734-4.0234 9.2734-9.4492-0.17578-5.6016-3.8516-9.4492-9.1016-9.4492z"/>
                                </g>
                                </svg>
                                <h3 className="font-bold century -mt-1"><span className="font-bold">Création de nom de domaine</span></h3>
                                 <p>Créez votre <strong>nom de domaine</strong> en .fr auprès de Linkweb{/*, registrar agréé <strong>Afnic</strong>, */} pour tous vos projets web sur le marché français.</p> 
                            </div>
                            <div className="w-full svgtransform md:w-1/4 mx-auto px-2 mb-10 md:mb-0 century text-center lg:text-left">
                                <svg width="50px" className="lg:mx-0 mx-auto block" fill="#37cfee" viewBox="0 0 700 700">
                                    <path d="m466.72 93.691c-6.2188 0.011719-12.176 2.5078-16.547 6.9336-4.3711 4.4258-6.7969 10.41-6.7344 16.629 0.058594 6.2188 2.6016 12.156 7.0586 16.496l52.82 52.773h-176.6c-6.2461-0.082032-12.262 2.3438-16.707 6.7344-4.4414 4.3867-6.9414 10.375-6.9336 16.621 0.007813 6.2461 2.5156 12.23 6.9688 16.609 4.4531 4.3828 10.473 6.7969 16.719 6.7031h177.05l-53.32 53.273c-4.5234 4.3438-7.1094 10.324-7.1719 16.594-0.066406 6.2734 2.3984 12.305 6.832 16.742 4.4336 4.4336 10.469 6.8945 16.738 6.832 6.2734-0.0625 12.254-2.6484 16.598-7.1719l92.922-92.832c4.3867-4.3789 6.8555-10.324 6.8555-16.523 0-6.1953-2.4688-12.141-6.8555-16.52l-92.922-92.832c-4.4062-4.5273-10.457-7.0742-16.773-7.0625zm-234.02 139.27c-6.0703 0.19531-11.824 2.7422-16.043 7.1094l-93.105 93.195v0.003906c-4.375 4.375-6.832 10.309-6.832 16.496s2.457 12.121 6.832 16.496l93.105 93.195v0.003907c5.8945 5.9023 14.488 8.2109 22.547 6.0547 8.0586-2.1523 14.355-8.4414 16.52-16.496 2.1641-8.0547-0.13281-16.652-6.0273-22.555l-53.23-53.273h176.96c6.2461 0.085938 12.27-2.332 16.715-6.7188 4.4492-4.3828 6.9531-10.371 6.9531-16.617 0-6.2461-2.5039-12.23-6.9531-16.617-4.4453-4.3828-10.469-6.8047-16.715-6.7148h-177.19l53.457-53.504c4.5273-4.3984 7.0781-10.445 7.0742-16.758-0.007813-6.3125-2.5703-12.352-7.1055-16.742s-10.656-6.7578-16.965-6.5586z" fill-rule="evenodd"/>
                                </svg>
                                <h3 className="font-bold century"><span className="font-bold">Transfert de nom de domaine</span></h3>
                                <p>Transférez votre nom de domaine de façon totalement sécurisée au sein de notre <strong>bureau d’enregistrement</strong>{/* agréé*/}.</p>
                            </div>
                            <div className="w-full svgtransform md:w-1/4 mx-auto px-2 mb-10 md:mb-0 century text-center lg:text-left">
                                <svg width="50px" className="lg:mx-0 mx-auto block" fill="#37cfee" viewBox="0 0 700 700">
                                    <defs>
                                    <symbol id="x" overflow="visible">
                                    <path d="m18.766-1.125c-0.96875 0.5-1.9805 0.875-3.0312 1.125-1.043 0.25781-2.1367 0.39062-3.2812 0.39062-3.3984 0-6.0898-0.94531-8.0781-2.8438-1.9922-1.9062-2.9844-4.4844-2.9844-7.7344 0-3.2578 0.99219-5.8359 2.9844-7.7344 1.9883-1.9062 4.6797-2.8594 8.0781-2.8594 1.1445 0 2.2383 0.13281 3.2812 0.39062 1.0508 0.25 2.0625 0.625 3.0312 1.125v4.2188c-0.98047-0.65625-1.9453-1.1406-2.8906-1.4531-0.94922-0.3125-1.9492-0.46875-3-0.46875-1.875 0-3.3516 0.60547-4.4219 1.8125-1.0742 1.1992-1.6094 2.8555-1.6094 4.9688 0 2.1055 0.53516 3.7617 1.6094 4.9688 1.0703 1.1992 2.5469 1.7969 4.4219 1.7969 1.0508 0 2.0508-0.14844 3-0.45312 0.94531-0.3125 1.9102-0.80078 2.8906-1.4688z"/>
                                    </symbol>
                                    <symbol id="e" overflow="visible">
                                    <path d="m13.734-11.141c-0.4375-0.19531-0.87109-0.34375-1.2969-0.4375-0.41797-0.10156-0.83984-0.15625-1.2656-0.15625-1.2617 0-2.2305 0.40625-2.9062 1.2188-0.67969 0.80469-1.0156 1.9531-1.0156 3.4531v7.0625h-4.8906v-15.312h4.8906v2.5156c0.625-1 1.3438-1.7266 2.1562-2.1875 0.82031-0.46875 1.8008-0.70312 2.9375-0.70312 0.16406 0 0.34375 0.011719 0.53125 0.03125 0.19531 0.011719 0.47656 0.039062 0.84375 0.078125z"/>
                                    </symbol>
                                    <symbol id="b" overflow="visible">
                                    <path d="m17.641-7.7031v1.4062h-11.453c0.125 1.1484 0.53906 2.0078 1.25 2.5781 0.70703 0.57422 1.7031 0.85938 2.9844 0.85938 1.0312 0 2.082-0.14844 3.1562-0.45312 1.082-0.3125 2.1914-0.77344 3.3281-1.3906v3.7656c-1.1562 0.4375-2.3125 0.76562-3.4688 0.98438-1.1562 0.22656-2.3125 0.34375-3.4688 0.34375-2.7734 0-4.9297-0.70312-6.4688-2.1094-1.5312-1.4062-2.2969-3.3789-2.2969-5.9219 0-2.5 0.75391-4.4609 2.2656-5.8906 1.5078-1.4375 3.582-2.1562 6.2188-2.1562 2.4062 0 4.332 0.73047 5.7812 2.1875 1.4453 1.4492 2.1719 3.3828 2.1719 5.7969zm-5.0312-1.625c0-0.92578-0.27344-1.6719-0.8125-2.2344-0.54297-0.57031-1.25-0.85938-2.125-0.85938-0.94922 0-1.7188 0.26562-2.3125 0.79688s-0.96484 1.2969-1.1094 2.2969z"/>
                                    </symbol>
                                    <symbol id="l" overflow="visible">
                                    <path d="m9.2188-6.8906c-1.0234 0-1.793 0.17188-2.3125 0.51562-0.51172 0.34375-0.76562 0.85547-0.76562 1.5312 0 0.625 0.20703 1.1172 0.625 1.4688 0.41406 0.34375 0.98828 0.51562 1.7188 0.51562 0.92578 0 1.7031-0.32812 2.3281-0.98438 0.63281-0.66406 0.95312-1.4922 0.95312-2.4844v-0.5625zm7.4688-1.8438v8.7344h-4.9219v-2.2656c-0.65625 0.92969-1.3984 1.6055-2.2188 2.0312-0.82422 0.41406-1.8242 0.625-3 0.625-1.5859 0-2.8711-0.45703-3.8594-1.375-0.99219-0.92578-1.4844-2.1289-1.4844-3.6094 0-1.7891 0.61328-3.1016 1.8438-3.9375 1.2383-0.84375 3.1797-1.2656 5.8281-1.2656h2.8906v-0.39062c0-0.76953-0.30859-1.332-0.92188-1.6875-0.61719-0.36328-1.5703-0.54688-2.8594-0.54688-1.0547 0-2.0312 0.10547-2.9375 0.3125-0.89844 0.21094-1.7305 0.52344-2.5 0.9375v-3.7344c1.0391-0.25 2.0859-0.44141 3.1406-0.57812 1.0625-0.13281 2.125-0.20312 3.1875-0.20312 2.7578 0 4.75 0.54688 5.9688 1.6406 1.2266 1.0859 1.8438 2.8555 1.8438 5.3125z"/>
                                    </symbol>
                                    <symbol id="c" overflow="visible">
                                    <path d="m7.7031-19.656v4.3438h5.0469v3.5h-5.0469v6.5c0 0.71094 0.14062 1.1875 0.42188 1.4375s0.83594 0.375 1.6719 0.375h2.5156v3.5h-4.1875c-1.9375 0-3.3125-0.39844-4.125-1.2031-0.80469-0.8125-1.2031-2.1797-1.2031-4.1094v-6.5h-2.4219v-3.5h2.4219v-4.3438z"/>
                                    </symbol>
                                    <symbol id="d" overflow="visible">
                                    <path d="m12.766-13.078v-8.2031h4.9219v21.281h-4.9219v-2.2188c-0.66797 0.90625-1.4062 1.5703-2.2188 1.9844s-1.7578 0.625-2.8281 0.625c-1.8867 0-3.4336-0.75-4.6406-2.25-1.2109-1.5-1.8125-3.4258-1.8125-5.7812 0-2.3633 0.60156-4.2969 1.8125-5.7969 1.207-1.5 2.7539-2.25 4.6406-2.25 1.0625 0 2 0.21484 2.8125 0.64062 0.82031 0.42969 1.5664 1.0859 2.2344 1.9688zm-3.2188 9.9219c1.0391 0 1.8359-0.37891 2.3906-1.1406 0.55078-0.76953 0.82812-1.8828 0.82812-3.3438 0-1.457-0.27734-2.5664-0.82812-3.3281-0.55469-0.76953-1.3516-1.1562-2.3906-1.1562-1.043 0-1.8398 0.38672-2.3906 1.1562-0.55469 0.76172-0.82812 1.8711-0.82812 3.3281 0 1.4609 0.27344 2.5742 0.82812 3.3438 0.55078 0.76172 1.3477 1.1406 2.3906 1.1406z"/>
                                    </symbol>
                                    <symbol id="k" overflow="visible">
                                    <path d="m10.5-3.1562c1.0508 0 1.8516-0.37891 2.4062-1.1406 0.55078-0.76953 0.82812-1.8828 0.82812-3.3438 0-1.457-0.27734-2.5664-0.82812-3.3281-0.55469-0.76953-1.3555-1.1562-2.4062-1.1562-1.0547 0-1.8594 0.38672-2.4219 1.1562-0.55469 0.77344-0.82812 1.8828-0.82812 3.3281 0 1.4492 0.27344 2.5586 0.82812 3.3281 0.5625 0.77344 1.3672 1.1562 2.4219 1.1562zm-3.25-9.9219c0.67578-0.88281 1.4219-1.5391 2.2344-1.9688 0.82031-0.42578 1.7656-0.64062 2.8281-0.64062 1.8945 0 3.4453 0.75 4.6562 2.25 1.207 1.5 1.8125 3.4336 1.8125 5.7969 0 2.3555-0.60547 4.2812-1.8125 5.7812-1.2109 1.5-2.7617 2.25-4.6562 2.25-1.0625 0-2.0078-0.21094-2.8281-0.625-0.8125-0.42578-1.5586-1.0859-2.2344-1.9844v2.2188h-4.8906v-21.281h4.8906z"/>
                                    </symbol>
                                    <symbol id="j" overflow="visible">
                                    <path d="m0.34375-15.312h4.8906l4.125 10.391 3.5-10.391h4.8906l-6.4375 16.766c-0.64844 1.6953-1.4023 2.8828-2.2656 3.5625-0.86719 0.6875-2 1.0312-3.4062 1.0312h-2.8438v-3.2188h1.5312c0.83203 0 1.4375-0.13672 1.8125-0.40625 0.38281-0.26172 0.67969-0.73047 0.89062-1.4062l0.14062-0.42188z"/>
                                    </symbol>
                                    <symbol id="i" overflow="visible">
                                    <path d="m2.5781-20.406h6.6875l4.6562 10.922 4.6719-10.922h6.6875v20.406h-4.9844v-14.938l-4.7031 11.016h-3.3281l-4.7031-11.016v14.938h-4.9844z"/>
                                    </symbol>
                                    <symbol id="a" overflow="visible">
                                    <path d="m9.6406-12.188c-1.0859 0-1.9141 0.39062-2.4844 1.1719-0.57422 0.78125-0.85938 1.9062-0.85938 3.375s0.28516 2.5938 0.85938 3.375c0.57031 0.77344 1.3984 1.1562 2.4844 1.1562 1.0625 0 1.875-0.38281 2.4375-1.1562 0.57031-0.78125 0.85938-1.9062 0.85938-3.375s-0.28906-2.5938-0.85938-3.375c-0.5625-0.78125-1.375-1.1719-2.4375-1.1719zm0-3.5c2.6328 0 4.6914 0.71484 6.1719 2.1406 1.4766 1.418 2.2188 3.3867 2.2188 5.9062 0 2.5117-0.74219 4.4805-2.2188 5.9062-1.4805 1.418-3.5391 2.125-6.1719 2.125-2.6484 0-4.7148-0.70703-6.2031-2.125-1.4922-1.4258-2.2344-3.3945-2.2344-5.9062 0-2.5195 0.74219-4.4883 2.2344-5.9062 1.4883-1.4258 3.5547-2.1406 6.2031-2.1406z"/>
                                    </symbol>
                                    <symbol id="w" overflow="visible">
                                    <path d="m7.8281-16.438v12.453h1.8906c2.1562 0 3.8008-0.53125 4.9375-1.5938 1.1328-1.0625 1.7031-2.6133 1.7031-4.6562 0-2.0195-0.57031-3.5547-1.7031-4.6094-1.125-1.0625-2.7734-1.5938-4.9375-1.5938zm-5.25-3.9688h5.5469c3.0938 0 5.3984 0.21875 6.9219 0.65625 1.5195 0.4375 2.8203 1.1875 3.9062 2.25 0.95703 0.91797 1.6641 1.9805 2.125 3.1875 0.46875 1.1992 0.70312 2.5586 0.70312 4.0781 0 1.543-0.23438 2.918-0.70312 4.125-0.46094 1.2109-1.168 2.2773-2.125 3.2031-1.0938 1.0547-2.4062 1.8047-3.9375 2.25-1.5312 0.4375-3.8281 0.65625-6.8906 0.65625h-5.5469z"/>
                                    </symbol>
                                    <symbol id="v" overflow="visible">
                                    <path d="m14.312-14.828v3.7188c-1.043-0.4375-2.0547-0.76562-3.0312-0.98438-0.98047-0.21875-1.9023-0.32812-2.7656-0.32812-0.92969 0-1.6211 0.11719-2.0781 0.34375-0.44922 0.23047-0.67188 0.58984-0.67188 1.0781 0 0.38672 0.17188 0.68359 0.51562 0.89062 0.34375 0.21094 0.95703 0.36719 1.8438 0.46875l0.85938 0.125c2.5078 0.32422 4.1953 0.85156 5.0625 1.5781 0.86328 0.73047 1.2969 1.8711 1.2969 3.4219 0 1.6367-0.60547 2.8672-1.8125 3.6875-1.1992 0.8125-2.9922 1.2188-5.375 1.2188-1.0234 0-2.0742-0.078125-3.1562-0.23438-1.0742-0.15625-2.1797-0.39453-3.3125-0.71875v-3.7188c0.96875 0.48047 1.9609 0.83984 2.9844 1.0781 1.0312 0.23047 2.0781 0.34375 3.1406 0.34375 0.95703 0 1.6758-0.12891 2.1562-0.39062 0.47656-0.26953 0.71875-0.66406 0.71875-1.1875 0-0.4375-0.16797-0.75781-0.5-0.96875-0.33594-0.21875-0.99609-0.38281-1.9844-0.5l-0.85938-0.10938c-2.1797-0.26953-3.7031-0.77344-4.5781-1.5156-0.875-0.73828-1.3125-1.8594-1.3125-3.3594 0-1.625 0.55078-2.8281 1.6562-3.6094 1.1133-0.78906 2.8203-1.1875 5.125-1.1875 0.89453 0 1.8359 0.074219 2.8281 0.21875 1 0.13672 2.082 0.35156 3.25 0.64062z"/>
                                    </symbol>
                                    <symbol id="h" overflow="visible">
                                    <path d="m2.3594-15.312h4.8906v15.312h-4.8906zm0-5.9688h4.8906v4h-4.8906z"/>
                                    </symbol>
                                    <symbol id="u" overflow="visible">
                                    <path d="m12.766-2.5938c-0.66797 0.88672-1.4062 1.543-2.2188 1.9688-0.8125 0.41797-1.7578 0.625-2.8281 0.625-1.8672 0-3.4062-0.73438-4.625-2.2031-1.2188-1.4766-1.8281-3.3516-1.8281-5.625 0-2.2891 0.60938-4.1641 1.8281-5.625 1.2188-1.4688 2.7578-2.2031 4.625-2.2031 1.0703 0 2.0156 0.21484 2.8281 0.64062 0.8125 0.41797 1.5508 1.0742 2.2188 1.9688v-2.2656h4.9219v13.766c0 2.457-0.77734 4.3359-2.3281 5.6406-1.5547 1.3008-3.8086 1.9531-6.7656 1.9531-0.94922 0-1.8711-0.074219-2.7656-0.21875-0.89844-0.14844-1.7969-0.37109-2.7031-0.67188v-3.8125c0.86328 0.48828 1.7031 0.85156 2.5156 1.0938 0.82031 0.23828 1.6484 0.35938 2.4844 0.35938 1.6016 0 2.7734-0.35156 3.5156-1.0469 0.75-0.69922 1.125-1.7969 1.125-3.2969zm-3.2188-9.5312c-1.0117 0-1.8047 0.375-2.375 1.125-0.5625 0.74219-0.84375 1.7969-0.84375 3.1719 0 1.3984 0.26953 2.4609 0.8125 3.1875 0.55078 0.71875 1.3516 1.0781 2.4062 1.0781 1.0195 0 1.8125-0.36719 2.375-1.1094 0.5625-0.75 0.84375-1.8008 0.84375-3.1562 0-1.375-0.28125-2.4297-0.84375-3.1719-0.5625-0.75-1.3555-1.125-2.375-1.125z"/>
                                    </symbol>
                                    <symbol id="g" overflow="visible">
                                    <path d="m17.75-9.3281v9.3281h-4.9219v-7.1406c0-1.3203-0.03125-2.2344-0.09375-2.7344s-0.16797-0.86719-0.3125-1.1094c-0.1875-0.3125-0.44922-0.55469-0.78125-0.73438-0.32422-0.17578-0.69531-0.26562-1.1094-0.26562-1.0234 0-1.8242 0.39844-2.4062 1.1875-0.58594 0.78125-0.875 1.8711-0.875 3.2656v7.5312h-4.8906v-15.312h4.8906v2.2344c0.73828-0.88281 1.5195-1.5391 2.3438-1.9688 0.83203-0.42578 1.75-0.64062 2.75-0.64062 1.7695 0 3.1133 0.54688 4.0312 1.6406 0.91406 1.0859 1.375 2.6562 1.375 4.7188z"/>
                                    </symbol>
                                    <symbol id="t" overflow="visible">
                                    <path d="m16.781-19.766v4.3125c-1.125-0.5-2.2266-0.875-3.2969-1.125-1.0625-0.25781-2.0703-0.39062-3.0156-0.39062-1.25 0-2.1797 0.17969-2.7812 0.53125-0.60547 0.34375-0.90625 0.88281-0.90625 1.6094 0 0.54297 0.20312 0.96875 0.60938 1.2812 0.40625 0.30469 1.1406 0.5625 2.2031 0.78125l2.25 0.45312c2.2695 0.44922 3.8789 1.1406 4.8281 2.0781 0.95703 0.92969 1.4375 2.2461 1.4375 3.9531 0 2.25-0.66797 3.9297-2 5.0312-1.3359 1.0938-3.3711 1.6406-6.1094 1.6406-1.3047 0-2.6055-0.125-3.9062-0.375-1.3047-0.23828-2.6055-0.59766-3.9062-1.0781v-4.4531c1.3008 0.69922 2.5625 1.2266 3.7812 1.5781 1.2188 0.34375 2.3906 0.51562 3.5156 0.51562 1.1562 0 2.0352-0.1875 2.6406-0.5625 0.61328-0.38281 0.92188-0.9375 0.92188-1.6562 0-0.63281-0.21094-1.125-0.625-1.4688-0.41797-0.34375-1.2461-0.65625-2.4844-0.9375l-2.0312-0.4375c-2.043-0.4375-3.5391-1.1328-4.4844-2.0938-0.9375-0.95703-1.4062-2.25-1.4062-3.875 0-2.0312 0.65625-3.5938 1.9688-4.6875s3.1953-1.6406 5.6562-1.6406c1.125 0 2.2734 0.085938 3.4531 0.25 1.1875 0.16797 2.4141 0.42188 3.6875 0.76562z"/>
                                    </symbol>
                                    <symbol id="f" overflow="visible">
                                    <path d="m2.1875-5.9688v-9.3438h4.9219v1.5312c0 0.83594-0.007813 1.875-0.015625 3.125-0.011719 1.25-0.015625 2.0859-0.015625 2.5 0 1.2422 0.03125 2.1328 0.09375 2.6719 0.070313 0.54297 0.17969 0.93359 0.32812 1.1719 0.20703 0.32422 0.47266 0.57422 0.79688 0.75 0.32031 0.16797 0.69141 0.25 1.1094 0.25 1.0195 0 1.8203-0.39062 2.4062-1.1719 0.58203-0.78125 0.875-1.8672 0.875-3.2656v-7.5625h4.8906v15.312h-4.8906v-2.2188c-0.74219 0.89844-1.5234 1.5586-2.3438 1.9844-0.82422 0.41406-1.7344 0.625-2.7344 0.625-1.7617 0-3.1055-0.53906-4.0312-1.625-0.92969-1.082-1.3906-2.6602-1.3906-4.7344z"/>
                                    </symbol>
                                    <symbol id="s" overflow="visible">
                                    <path d="m12.422-21.281v3.2188h-2.7031c-0.6875 0-1.1719 0.125-1.4531 0.375-0.27344 0.25-0.40625 0.6875-0.40625 1.3125v1.0625h4.1875v3.5h-4.1875v11.812h-4.8906v-11.812h-2.4375v-3.5h2.4375v-1.0625c0-1.6641 0.46094-2.8984 1.3906-3.7031 0.92578-0.80078 2.3672-1.2031 4.3281-1.2031z"/>
                                    </symbol>
                                    <symbol id="r" overflow="visible">
                                    <path d="m16.547-12.766c0.61328-0.94531 1.3477-1.6719 2.2031-2.1719 0.85156-0.5 1.7891-0.75 2.8125-0.75 1.7578 0 3.0977 0.54688 4.0156 1.6406 0.92578 1.0859 1.3906 2.6562 1.3906 4.7188v9.3281h-4.9219v-7.9844-0.35938c0.007813-0.13281 0.015625-0.32031 0.015625-0.5625 0-1.082-0.16406-1.8633-0.48438-2.3438-0.3125-0.48828-0.82422-0.73438-1.5312-0.73438-0.92969 0-1.6484 0.38672-2.1562 1.1562-0.51172 0.76172-0.77344 1.8672-0.78125 3.3125v7.5156h-4.9219v-7.9844c0-1.6953-0.14844-2.7852-0.4375-3.2656-0.29297-0.48828-0.8125-0.73438-1.5625-0.73438-0.9375 0-1.6641 0.38672-2.1719 1.1562-0.51172 0.76172-0.76562 1.8594-0.76562 3.2969v7.5312h-4.9219v-15.312h4.9219v2.2344c0.60156-0.86328 1.2891-1.5156 2.0625-1.9531 0.78125-0.4375 1.6406-0.65625 2.5781-0.65625 1.0625 0 2 0.25781 2.8125 0.76562 0.8125 0.51172 1.4258 1.2305 1.8438 2.1562z"/>
                                    </symbol>
                                    <symbol id="q" overflow="visible">
                                    <path d="m17.75-9.3281v9.3281h-4.9219v-7.1094c0-1.3438-0.03125-2.2656-0.09375-2.7656s-0.16797-0.86719-0.3125-1.1094c-0.1875-0.3125-0.44922-0.55469-0.78125-0.73438-0.32422-0.17578-0.69531-0.26562-1.1094-0.26562-1.0234 0-1.8242 0.39844-2.4062 1.1875-0.58594 0.78125-0.875 1.8711-0.875 3.2656v7.5312h-4.8906v-21.281h4.8906v8.2031c0.73828-0.88281 1.5195-1.5391 2.3438-1.9688 0.83203-0.42578 1.75-0.64062 2.75-0.64062 1.7695 0 3.1133 0.54688 4.0312 1.6406 0.91406 1.0859 1.375 2.6562 1.375 4.7188z"/>
                                    </symbol>
                                    <symbol id="p" overflow="visible">
                                    <path d="m2.5781-20.406h5.875l7.4219 14v-14h4.9844v20.406h-5.875l-7.4219-14v14h-4.9844z"/>
                                    </symbol>
                                    <symbol id="o" overflow="visible">
                                    <path d="m2.5781-20.406h8.7344c2.5938 0 4.582 0.57812 5.9688 1.7344 1.3945 1.1484 2.0938 2.7891 2.0938 4.9219 0 2.1367-0.69922 3.7812-2.0938 4.9375-1.3867 1.1562-3.375 1.7344-5.9688 1.7344h-3.4844v7.0781h-5.25zm5.25 3.8125v5.7031h2.9219c1.0195 0 1.8047-0.25 2.3594-0.75 0.5625-0.5 0.84375-1.2031 0.84375-2.1094 0-0.91406-0.28125-1.6172-0.84375-2.1094-0.55469-0.48828-1.3398-0.73438-2.3594-0.73438z"/>
                                    </symbol>
                                    <symbol id="n" overflow="visible">
                                    <path d="m2.3594-15.312h4.8906v15.031c0 2.0508-0.49609 3.6172-1.4844 4.7031-0.98047 1.082-2.4062 1.625-4.2812 1.625h-2.4219v-3.2188h0.85938c0.92578 0 1.5625-0.21094 1.9062-0.625 0.35156-0.41797 0.53125-1.2461 0.53125-2.4844zm0-5.9688h4.8906v4h-4.8906z"/>
                                    </symbol>
                                    <symbol id="m" overflow="visible">
                                    <path d="m14.719-14.828v3.9844c-0.65625-0.45703-1.3242-0.79688-2-1.0156-0.66797-0.21875-1.3594-0.32812-2.0781-0.32812-1.3672 0-2.4336 0.40234-3.2031 1.2031-0.76172 0.79297-1.1406 1.9062-1.1406 3.3438 0 1.4297 0.37891 2.543 1.1406 3.3438 0.76953 0.79297 1.8359 1.1875 3.2031 1.1875 0.75781 0 1.4844-0.10938 2.1719-0.32812 0.6875-0.22656 1.3203-0.56641 1.9062-1.0156v4c-0.76172 0.28125-1.5391 0.48828-2.3281 0.625-0.78125 0.14453-1.5742 0.21875-2.375 0.21875-2.7617 0-4.9219-0.70703-6.4844-2.125-1.5547-1.4141-2.3281-3.3828-2.3281-5.9062 0-2.5312 0.77344-4.5039 2.3281-5.9219 1.5625-1.4141 3.7227-2.125 6.4844-2.125 0.80078 0 1.5938 0.074219 2.375 0.21875 0.78125 0.13672 1.5547 0.35156 2.3281 0.64062z"/>
                                    </symbol>
                                    </defs>
                                    <g>
                                    <path d="m581.28 217.84-85.121-48.719-48.719 85.121c-5.6016 9.5195-2.2383 21.281 7.2812 26.879 2.8008 1.6797 6.1602 2.8008 9.5195 2.8008 6.7188 0 13.441-3.3594 16.801-10.078l16.801-29.68c11.199 56-8.3984 115.36-53.762 152.88-54.32 45.359-133.28 49.84-191.52 9.5195-8.9609-6.1602-21.281-3.9219-27.441 5.0391-6.1602 8.9609-3.9219 21.281 5.0391 27.441 33.602 22.961 72.238 34.16 111.44 34.16 45.359 0 91.281-15.68 127.68-46.48 56-47.039 80.641-120.4 67.199-189.84l24.641 14c9.5195 5.6016 21.281 2.2383 26.879-7.2812 5.6016-8.4023 2.8008-20.16-6.7188-25.762z"/>
                                    <path d="m225.12 269.36c-9.5195-4.4805-21.281-0.55859-26.32 8.9609l-12.879 25.199c-9.5195-54.879 10.078-112.56 54.32-149.52 54.32-45.359 133.28-49.84 191.52-9.5195 8.9609 6.1602 21.281 3.9219 27.441-5.0391 6.1602-8.9609 3.9219-21.281-5.0391-27.441-72.801-49.84-171.36-44.801-239.12 12.32-57.121 47.602-81.762 123.76-66.082 194.88l-29.121-14.559c-9.5195-5.0391-21.281-1.1211-26.32 8.3984-5.0391 9.5195-1.1211 21.281 8.3984 26.32l87.359 44.238 44.238-87.359c5.043-10.078 1.125-21.84-8.3945-26.879z"/> 
                                    </g>
                                </svg>
                                <h3 className="font-bold century"><span className="font-bold">Renouvellement de nom de domaine</span></h3>
                                <p>Renouvelez votre nom de domaine en toute facilité auprès de notre <strong>bureau d’enregistrement Linkweb</strong>.</p>
                            </div>
                        </div>
                    </section>
                </section>
                <section className="phototext py-10">
                    <section className="text-center text-black text-2xl mx-auto flex-col flex items-center lg:mx-0 century mt-16 mb-10">
                    <h2 className="century text-center lg:text-left text-3xl md:text-5xl leading-none">Des noms de domaines en .fr pour votre site internet</h2>
                        <br />
                        <hr className="border-solid w-1/6 border-white"></hr>
                        <br />
                        <h3 /*data-aos='fade-left'*/ className="text-xl max-w-5xl text-center century text-black px-4 pb-0">Vous créez un site web pour votre entreprise ? Assurez-vous d'enregistrer un nom de domaine .fr !</h3> <br/>
                    </section>
                    <section className="w-full bg-bleu text-white flex flex-row justify-center py-12">
                        <table className="flex justify-center flex-col items-center century">
                            <thead>
                                <tr>
                                    <th colspan="2"><h3 className="text-3xl">Nos tarifs annuels Hors Taxes</h3></th>
                                </tr>
                            </thead>
                            <tbody className="py-6 text-xl">
                                <tr style={{borderBottom:'1px solid #fff'}}>
                                    <td className="py-5 pr-16 md:pr-32">Création de nom de domaine .fr</td>
                                    <td className="py-5">5,90 €</td>
                                </tr>
                                <tr style={{borderBottom:'1px solid #fff'}}>
                                    <td className="py-5">Transfert de nom de domaine .fr</td>
                                    <td className="py-5">5,90 €</td>
                                </tr>
                                <tr className="py-2">
                                    <td className="py-5">Renouvellement de nom de domaine .fr</td>
                                    <td className="py-5">6.90 €</td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                    <section className="phototext py-10">
                    <section className="w-full flex flex-col text-black justify-center century my-10 px-4">
                        <h2 className="century text-center text-3xl md:text-5xl leading-none">Et ensuite ?</h2>
                        <br />
                        <div className="line-title my-4 ">
                            <br />
                            <hr className="w-1/2 border-bleu lg:w-1/12" />
                            <br/>
                        </div>
                        <br/>
                        <div className="flex justify-center flex-col items-center">
                            <h3 /*data-aos='fade-left'*/ className="text-xl text-center century text-black px-4 pb-0">Transformez votre nom de domaine en moteur de visibilité pour tous vos projets web.</h3>
                        </div>
                    </section>
                    
                    <section className="w-full my-10 flex flex-col lg:flex-row items-center justify-start">
                        <div className="w-full flex justify-center items-center lg:w-5/12">
                            <img className="w-full" src={mac} alt="Linkweb" />
                        </div>
                        <div className="w-full lg:w-1/2 my-10  flex flex-col">
                            <div className="w-full flex-col md:flex-row flex px-auto">
                                <div className="blocchiffres mx-auto md:mx-0 block century text-bleu " /*data-aos="fade-up"*/>
                                    <p>1</p>
                                </div>
                                <div className="w-full px-6 lg:px-6 flex flex-col justify-center" style={{ maxWidth: '1000px' }}>
                                    <h3 className="century capitalize text-2xl tracking-wider pb-3 leading-tight text-justify" /*data-aos="zoom-in"*/>
                                        <span className="text-bleu">/</span>HÉBERGEMENT WEB
                                </h3>

                                    <h4 className="century">Faites le choix d'une solution d'hébergement adaptée à vos besoins et sécurisée.</h4>
                                    <br />
                                    <p className="w-full century pb-4 text-justify">Nous vous proposons des <strong>solutions d'hébergement sécurisées</strong> et parfaitement adaptées à vos besoins. Nous réalisons toutes les démarches, vous n'avez rien à faire.
                                </p>
                                    {/*<Button url="/referencement-de-site-internet-47-31/" text="Notre formule SEO" position="left" />*/}
                                    <br />
                                </div>
                            </div>
                            <div className="w-full flex-col md:flex-row flex px-auto">
                                <div className="blocchiffres mx-auto block md:mx-0 century text-bleu mb-6" /*data-aos="fade-up"*/>
                                    <p>2</p>
                                </div>
                                <div className="w-full px-12 lg:px-6 flex flex-col justify-center">
                                    <h3 className="century capitalize text-2xl tracking-wider pb-3 leading-tight text-justify" /*data-aos="zoom-in"*/>
                                        <span className="text-bleu">/</span>CRÉATION DE SITE INTERNET
                                    </h3>

                                    <h4 className="century">Créez un site internet ergonomique, intuitif et performant à destination de vos utilisateurs cibles.</h4>
                                    <br />
                                    <p className="w-full century pb-4 text-justify">Nous mettons en place votre projet en fonction de vos objectifs. Les <strong>sites internet</strong> que nous créons sont parfaitement adaptés à vos besoins, à votre <strong>image de marque</strong> ainsi qu'à votre <strong>charte graphique</strong>.
                                    </p>
                                    <Button url="/creation-site-internet-toulouse/" text="En savoir plus sur la création de site internet" position="left" />
                                    <br />
                                </div>
                            </div>
                            <div className="w-full flex-col md:flex-row flex px-auto">
                                <div className="blocchiffres mx-auto block md:mx-0 century text-bleu mb-6" /*data-aos="fade-up"*/>
                                    <p>3</p>
                                </div>
                                <div className="w-full px-12 lg:px-6 flex flex-col justify-center">
                                    <h3 className="century capitalize text-2xl tracking-wider pb-3 leading-tight text-justify" /*data-aos="zoom-in"*/>
                                        <span className="text-bleu">/</span>GESTION DE SITE INTERNET
                                    </h3>

                                    <h4 className="century">Déléguez la gestion technique et administrative de votre site internet à des professionnels compétents.</h4>
                                    <br />
                                    <p className="century pb-4 text-justify">Notre équipe se charge de toutes les démarches pour <strong>maintenir votre site internet en ligne</strong>, et ce, qu'il s'agisse du <strong>renouvellement de votre nom de domaine</strong> ou de la <strong>maintenance technique</strong> de votre site.
                                    </p>
                                    {/*<Button url="/contact-agence-web-toulouse/" text="EN SAVOIR PLUS" position="left" />*/}
                                </div>
                            </div>
                            <div className="w-full flex-col md:flex-row flex px-auto">
                                <div className="blocchiffres mx-auto block md:mx-0 century text-bleu mb-6" /*data-aos="fade-up"*/>
                                    <p>4</p>
                                </div>
                                <div className="w-full px-12 lg:px-6 flex flex-col justify-center">
                                    <h3 className="century capitalize text-2xl tracking-wider pb-3 leading-tight text-justify" /*data-aos="zoom-in"*/>
                                        <span className="text-bleu">/</span>RÉFÉRENCEMENT
                                    </h3>

                                    <h4 className="century">Rendez votre nom de domaine visible grâce à une stratégie de référencement naturel taillée pour vous.</h4>
                                    <br />
                                    <p className="century pb-4 text-justify">Nous développons votre <strong>stratégie de référencement naturel</strong> en fonction de vos objectifs, vos cibles ainsi que votre marché. Obtenir de la visibilité sur le Web est indispensable pour <strong>dynamiser votre projet en ligne</strong>.
                                    </p>
                                    <Button url="/referencement-toulouse/" text="En savoir plus sur le référencement" position="left" />
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
                </section>
                <section className="flex w-full flex-col mx-auto px-0 my-4">
                    <section className="text-center text-black text-2xl mx-auto lg:mx-0 century mb-10">
                        <br />
                        <h2 className="century text-3xl md:text-5xl text-black leading-none">Linkweb : bureau d’enregistrement{/* AFNIC*/}</h2>
                        <br />
                        <hr className="border-solid w-1/6 border-bleu"></hr>
                    </section>
                </section>
                <section className="bloctextimg w-full md:w-10/12 mx-auto lg:block lg:mx-auto xl:w-full flex flex-col lg:flex-row justify-center">
                    <div className="w-full image lg:w-1/2 xl:w-2/5 lg:absolute flex justify-center lg:mt-48 xl:mt-24 pr-0 md:pr-16 lg:justify-center lg:items-center">
                        <img src={imac} alt="Référencement Toulouse" style={{ maxHeight: '450px' }} />
                    </div>
                    <div className="text century w-full text-justify mr-16 lg:w-3/5 xl:w-2/5 md:p-20 lg:pl-32 p-10 lg:py-10 text-black text-sm xl:text-md" style={{ backgroundColor: '#d3d3d352' }}>
                        <h4 className="century text-xl"><span className="font-bold">Linkweb est un bureau d'enregistrement{/* officiel agréé par l'Afnic*/}. Nous sommes donc en mesure de répondre à vos attentes pour la création de noms de domaine en .fr. </span></h4>
                        <br />
                        <p style={{ fontSize: '16px' }}>
                            Que vous lanciez une nouvelle activité ou que vous développiez une activité existante, un <strong>nom de domaine .fr</strong> est le moyen idéal de montrer à vos clients que vous souhaitez sérieusement faire des affaires en France.<br />
                            <br />
                            <strong>Créer un nom de domaine avec Linkweb</strong> est simple et rapide. Nous vous guidons tout au long du processus afin que vous puissiez mettre votre site en ligne en un rien de temps.<br/>
                            <br />
                            Une fois votre <strong>nom de domaine en poche</strong>, nous vous proposons une prestation tout-en-un. De l’<strong>enregistrement de votre nom de domaine</strong> jusqu’à la mise en ligne de votre site, nous nous occupons de tout. En souscrivant à notre offre, vous pourrez compter sur la mise en place d’une <strong>solution d’hébergement web</strong> sécurisée, la <strong>création de votre site internet</strong>, sa maintenance ainsi que la définition d’une stratégie de référencement naturel.<br />
                        </p>
                    </div>

                    <br />
                </section>
                <section className="w-full flex my-12 flex-col items-center lg:flex-row text-white bg-bleu century text-center mx-auto lg:text-justify px-16 xl:px-48 py-16">
                    <div className="w-full lg:w-8/12 text-2xl">
                        <h3>Vous souhaitez travailler avec <span className="font-bold">notre agence SEO à Toulouse</span> ?</h3>
                        <h4>Linkweb fait partie des agences qui soutiennent les professionnels souhaitant développer leur présence sur Internet.</h4>
                    </div>
                    <div className="w-full mx-auto block lg:w-3/12">
                        {/*<ButtonPage  text="CONTACTEZ-NOUS" url="/contact-agence-web-toulouse/" color="darkgrey" colortext="white"/>*/}
                        <RoundButton url="/contact-agence-web-toulouse/" color="darkgrey" colortext="white" text="CONTACTEZ-NOUS" />
                    </div>
                </section>
                <section className="flex w-full flex-col mx-auto px-0 py-6 my-4 lg:my-0">
                    <section className="text-center text-black text-2xl mx-auto lg:mx-0 century mt-16 mb-10 lg:mb-2">
                        <h2 className="century text-3xl md:text-5xl text-black leading-none">Les dernières actualités</h2>
                        <br />
                        <hr className="border-solid w-1/6 border-bleu"></hr>

                    </section>
                </section>
                <section className="max-w-6xl mx-auto flex flex-col align-center flex flex-col justify-center items-center justify-center text-center mt-12 mb-3">
                    <LastPosts lastposts={this.props.data.allWpPost.edges} />
                    <div className="w-2/5 flex flex-row justify-center items-center">
                        <RoundButton url="/actualite-digitale/" text="Accéder au Blog" />
                    </div>
                    <br />
                </section>
                <SimpleMap />
                {/* </PageTransition> */}
            </Layout>
        );
    }
};


export const query = graphql`
query lastsThreePostsNomsdedomaine {
    allWpPost(limit: 3) {
      edges {
        node {
          id
          title
          slug
          modified(locale: "fr", formatString: "DD/MM/YYYY")
          date(locale: "fr", formatString: "dddd DD MMMM YYYY")
          link
          featuredImage {
            node {
                sourceUrl
            }
          }
        }
      }
    }
  }
  
    
`
export default Nomsdedomaine;
